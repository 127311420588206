/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";
@import "primeng/resources/primeng.css";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

@font-face {
    font-family: Nunito-bold;
    src: url('./assets/fonts/Nunito/NunitoSans_10pt-Bold.ttf');
  }

  @font-face {
    font-family: Nunito-Light;
    src: url('./assets/fonts/Nunito/NunitoSans_10pt-Light.ttf');
  }

  @font-face {
    font-family: Nunito-Medium;
    src: url('./assets/fonts/Nunito/NunitoSans_10pt-Medium.ttf');
  }

  @font-face {
    font-family: Nunito-Regular;
    src: url('./assets/fonts/Nunito/NunitoSans_10pt-Regular.ttf');
  }

  @font-face {
    font-family: Nunito-semibold;
    src: url('./assets/fonts/Nunito/NunitoSans_10pt-SemiBold.ttf');
  }

.button-bigheight{
    height: 3.8rem;
    border-radius: 1em;
    margin-right: 1.8rem;
}

body {
    font-family: Nunito-Regular;
}

.layout-sidebar .layout-menu ul a:hover,
.layout-sidebar .layout-menu ul a.active-route{
    background-color: #ecf3ff!important;
    border-radius: 10px!important;
    color: #3f8cff!important;
  }

  .layout-container.layout-drawer .layout-sidebar .layout-menu ul {
    padding-inline: 10px;
    width: fit-content;
}


.layout-container.layout-drawer .layout-sidebar.active .layout-menu ul {
  width: 100%;
}


.left-sidebar.layout-sidebar .layout-root-menuitem{
  width: fit-content;
}

.left-sidebar.layout-sidebar.active .layout-root-menuitem{
  width: 100%;
}

.layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a span{
width: auto;
}

.layout-container.layout-drawer .layout-sidebar .layout-menu ul a span{
width: 0;
}

.layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a{
justify-content: flex-start;
}

.layout-container.layout-drawer .layout-sidebar .layout-menu ul a{
justify-content: center;
}

.layout-container.layout-drawer .layout-sidebar .layout-menu ul li {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    width: fit-content;
}

.layout-container.layout-drawer .layout-sidebar.active .layout-menu ul li {
  width: 100%;
}

.layout-sidebar .layout-menu ul a .layout-menuitem-icon{
    color: #4b5563;
}

.layout-sidebar .layout-menu ul a:hover .layout-menuitem-icon,
.layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon{
    color: #3f8cff!important;
  }


  .iconEstudiantes {
    background-image: url("./assets/new-icons/estudiantesInactive.svg");
      height: 24px!important;
      width: 24px!important;
      background-size: 24px;

  }

.layout-sidebar .layout-menu ul a:hover .layout-menuitem-icon.iconEstudiantes,
.layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon.iconEstudiantes {
    background-image: url("./assets/new-icons/estudiantesActive.svg");
    height: 24px;
    width: 24px;
    background-size: 24px;
}

.layout-container.layout-drawer .layout-sidebar .layout-menu ul a{
    padding: 0.75rem 1rem;
    width: fit-content;
}

.layout-container.layout-drawer .layout-sidebar.active .layout-menu ul a{
  width: 100%;
}


.iconResumen {
    background-image: url("./assets/new-icons/resumenInactive.svg");
    height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }
  
  .layout-sidebar .layout-menu ul a:hover .layout-menuitem-icon.iconResumen,
  .layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon.iconResumen{
    background-image: url("./assets/new-icons/resumenActive.svg");
    height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }

  .iconResultados {
    background-image: url("./assets/new-icons/resultadosInactive.svg");
    height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }
  
    .layout-sidebar .layout-menu ul a:hover .layout-menuitem-icon.iconResultados,
    .layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon.iconResultados {
    background-image: url("./assets/new-icons/resultadosActive.svg");
    height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }

  .iconGrupos {
    background-image: url("./assets/new-icons/gruposInactive.svg");
        height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }
  
  .layout-sidebar .layout-menu ul a:hover .layout-menuitem-icon.iconGrupos,
  .layout-sidebar .layout-menu ul a.active-route .layout-menuitem-icon.iconGrupos{
    background-image: url("./assets/new-icons/gruposActive.svg");
        height: 24px!important;
    width: 24px!important;
    background-size: 24px;
  }

  .layout-sidebar .layout-menu ul a  .layout-menuitem-icon{
    width: 24px!important;
  }


  .layout-sidebar .layout-menu ul a .layout-menuitem-icon{
    margin:0;
  }

  .layout-container.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a .layout-menuitem-icon{
    margin-right: 0.5rem;
  }

  .closeMenu .layout-menu .layout-root-menuitem .layout-menuitem-root-text{
    width: 0!important;
  }
  



 p-multiselectitem > li > .p-checkbox {
  display: none;
}


/* Color de fondo cuando el ítem está seleccionado */
p-multiselectitem li[aria-selected="true"] {
  background-color: rgba(63, 140, 255, .1199) !important;
  font-weight: 700 !important;
  border-radius: 14px;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 10px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  padding: 0.5rem 1.25rem;
  margin-bottom: 8px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: rgba(63, 140, 255, .1199) !important;
  font-weight: 700 !important;
  border-radius: 14px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus{
  color: black;
  background: transparent;
}


.p-multiselect-panel.p-component{
  margin-top: 10px;
  border-radius: 14px;
  overflow: hidden;
}

.p-multiselect-header {
  border-radius: 14px 14px 0 0;
}

.layout-sidebar .layout-menu ul a i{
  color:#7d8592!important;
}